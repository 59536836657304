import React, { useEffect, useLayoutEffect } from 'react'

import { PublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from '../authConfig';

interface AppProps {
    instance: PublicClientApplication;
}

export default function Login({ instance }: AppProps) {
    // const activeAccount = instance.getActiveAccount();


    useLayoutEffect(() => {
        if (instance.getActiveAccount()) {
            return; // Prevent unnecessary login attempt
        }

        instance.loginRedirect({
            ...loginRequest,
            prompt: 'create',
            redirectStartPage: window.location.href,
        }).catch((error) => console.error("Login redirect failed:", error));

    }, [instance]);
    return <div></div>
}
