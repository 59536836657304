import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from './authConfig';
import { PublicClientApplication } from "@azure/msal-browser";

interface AppProps {
    instance: PublicClientApplication;
}

export default function RedirectHandler({ instance }: AppProps) {
    const navigate = useNavigate();

    useEffect(() => {
        instance.handleRedirectPromise()
            .then((response) => {
                if (response) {
                    navigate('/');
                } else {
                    const activeAccount = instance.getActiveAccount();
                    if (activeAccount) {
                        navigate('/'); // User is already authenticated, no need to login
                    } else {
                        console.warn("No active account found. Attempting silent login.");
                        navigate('/login')
                    }
                }
            })
            .catch((error) => {
                console.error("Error handling redirect:", error);
                navigate('/login');
            });
    }, [instance, navigate]);

    return <p></p>;
}
